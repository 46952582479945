import { ReactNode, useMemo, useRef } from 'react';
import { EmployeeDto, TranslationDto } from '../../../../data-access';
import _, { uniqBy } from 'lodash';

export interface GenerateCvDropdownItem {
  key: string;
  header: string;
  content?: ReactNode;
  disabled?: boolean;
}

export interface GenerateCvDropDownItems {
  educations: GenerateCvDropdownItem[];
  projects: GenerateCvDropdownItem[];
  sectors: GenerateCvDropdownItem[];
  skills: GenerateCvDropdownItem[];
  certificates: GenerateCvDropdownItem[];
}

export const useGenerateCvDropdownItems = (
  employee: EmployeeDto | undefined,
  language: keyof TranslationDto
): GenerateCvDropDownItems => {
  const previous = useRef<GenerateCvDropDownItems | undefined>();

  const result = useMemo(
    () => ({
      educations:
        employee?.educations
          .map<GenerateCvDropdownItem>((item) => ({
            key: item.id,
            header: item.degree.name[language],
          }))
          .sort((a, b) => a.header.localeCompare(b.header)) ?? [],
      projects: [
        ...(employee?.templateBasedProjects.map((p) => ({
          id: p.projectTemplate.id,
          name: p.projectTemplate.name,
          isReference: p.isReferenceProject,
        })) ?? []),
        ...(employee?.projects.map((p) => ({
          id: p.id,
          name: p.name,
          isReference: p.isReferenceProject,
        })) ?? []),
      ]
        .sort((a, b) =>
          a.isReference && !b.isReference
            ? -1
            : !a.isReference && b.isReference
              ? 1
              : a.name[language].localeCompare(b.name[language])
        )
        .map<GenerateCvDropdownItem>((item) => ({
          key: item.id,
          header: item.name[language],
        })),
      sectors: uniqBy(
        [
          ...(employee?.templateBasedProjects.map((p) => p.projectTemplate.sector) ?? []),
          ...(employee?.projects.map((p) => p.sector) ?? []),
        ],
        (item) => item.id
      )
        .map<GenerateCvDropdownItem>((item) => ({
          key: item.id,
          header: item.name[language],
        }))
        .sort((a, b) => a.header.localeCompare(b.header)),
      skills:
        employee?.skills
          .sort((a, b) => b.level - a.level)
          .map<GenerateCvDropdownItem>((item) => ({
            key: item.skill.id,
            header: item.skill.name,
          })) ?? [],
      certificates:
        employee?.certificates
          .map<GenerateCvDropdownItem>((item) => ({
            key: item.certificate.id,
            header: item.certificate.name[language],
          }))
          .sort((a, b) => a.header.localeCompare(b.header)) ?? [],
    }),
    [
      employee?.certificates,
      employee?.educations,
      employee?.projects,
      employee?.skills,
      employee?.templateBasedProjects,
      language,
    ]
  );

  // for this list to be effectively stable, we need to do a deep comparison.
  // the employee dto changes every render.
  if (!_.isEqual(result, previous.current)) {
    previous.current = result;
  }

  return previous.current as GenerateCvDropDownItems;
};
