import {
  Button,
  ChevronStartIcon,
  Flex,
  FlexItem,
  Menu,
  tabListBehavior,
  Text,
  DownloadIcon,
  PersonIcon,
} from '@fluentui/react-northstar';
import { EmployeeMasterDataTab } from '../components/EmployeeMasterData/EmployeeMasterDataTab';
import { EmployeeProjectsTab } from '../components/EmployeeProjects/EmployeeProjectsTab';
import { EmployeeAvatar } from '../components/EmployeeTable/EmployeeAvatar';
import { useEmployee } from '../data-access';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { BasicLayout } from '../components/Layout/Layout';
import { CvDownloadDialog } from '../components/EmployeeTable/CvDownload/CvDownloadDialog';
import { useAuthInfo } from '../auth/use-authinfo';
import { DiamondIcon, DocumentIcon } from '../icons';
import { EmployeeSkillsTab } from '../components/EmployeeSkills/EmployeeSkillsTab';
import { maxProjectsCount } from '../components/EmployeeTable/CvDownload/CvDownloadDialogForm';

export const EmployeeDetailPage = () => {
  const { t } = useTranslation();
  const { canEditProfile, isOwnProfile } = useAuthInfo();

  const navigation = useHistory();
  const [employeeTab, setEmployeeTab] = useState<'masterData' | 'skills' | 'projects'>(
    'masterData'
  );
  const [isCvDownloadDialogOpen, setCvDownloadDialogVisibility] = useState(false);
  const [cvLanguage, setCvLanauge] = useState<'en' | 'de'>('en');

  const { id } = useParams<{ id: string }>();
  const { data: employee } = useEmployee(id);

  const maxReferenceProjectsReached = useMemo(() => {
    let count = 0;
    if (employee?.projects) {
      count += employee.projects.filter((x) => x.isReferenceProject).length;
    }
    if (employee?.templateBasedProjects) {
      count += employee.templateBasedProjects.filter((x) => x.isReferenceProject).length;
    }
    return count >= maxProjectsCount;
  }, [employee?.projects, employee?.templateBasedProjects]);

  const displayTab = (activeIndex: number) => {
    switch (activeIndex) {
      case 0:
        setEmployeeTab('masterData');
        break;
      case 1:
        setEmployeeTab('skills');
        break;
      case 2:
        setEmployeeTab('projects');
        break;
      default:
        setEmployeeTab('masterData');
        break;
    }
  };

  const isEditable = canEditProfile(employee?.id);
  const showSensitiveData = isOwnProfile(employee?.id);

  return (
    <BasicLayout>
      <Flex column>
        {isCvDownloadDialogOpen && employee && (
          <CvDownloadDialog
            isOpen={isCvDownloadDialogOpen}
            setIsOpen={setCvDownloadDialogVisibility}
            language={cvLanguage}
            employee={employee}
            canEditProfile={isEditable}
          />
        )}
        <FlexItem align="start" styles={{ marginBottom: '2rem' }}>
          <Button
            icon={<ChevronStartIcon />}
            iconPosition="before"
            text
            content={t('employee-detail.back-button-text')}
            primary
            onClick={() => navigation.push(`/employees`)}
          />
        </FlexItem>
        <Flex column gap="gap.large">
          <Flex gap="gap.medium" vAlign="center">
            <EmployeeAvatar
              firstName={employee?.firstName ?? ''}
              lastName={employee?.lastName ?? ''}
              id={employee?.id}
              photo={employee?.photo?.photoUri}
              size="largest"
            />
            <FlexItem align="center">
              <Flex column>
                <Text
                  content={`${employee?.firstName ?? ''} ${employee?.lastName ?? ''}`}
                  size="largest"
                />
                <Text content={employee?.role} />
              </Flex>
            </FlexItem>
          </Flex>
          <Flex>
            <Menu
              defaultActiveIndex={0}
              items={[
                {
                  key: 'master data',
                  content: (
                    <Flex gap="gap.small" hAlign="center" styles={{ minWidth: '7rem' }}>
                      <PersonIcon outline styles={{ marginTop: '0.1rem' }} />
                      <Text content={t('employee-detail.tab1')} />
                    </Flex>
                  ),
                },
                {
                  key: 'skills',
                  content: (
                    <Flex gap="gap.small" hAlign="center" styles={{ minWidth: '7rem' }}>
                      <DiamondIcon outline styles={{ marginTop: '0.1rem' }} />
                      <Text content={t('employee-detail.tab2')} />
                    </Flex>
                  ),
                },
                {
                  key: 'projects',
                  content: (
                    <Flex gap="gap.small" hAlign="center" styles={{ minWidth: '7rem' }}>
                      <DocumentIcon outline styles={{ marginTop: '0.1rem' }} />
                      <Text content={t('employee-detail.tab3')} />
                    </Flex>
                  ),
                },
              ]}
              primary
              accessibility={tabListBehavior}
              underlined
              onActiveIndexChange={(e, d) => displayTab(d?.activeIndex as number)}
            />
            <FlexItem push>
              <Flex gap="gap.small">
                <Button
                  icon={<DownloadIcon />}
                  iconPosition="before"
                  primary
                  onClick={() => {
                    setCvLanauge('en');
                    setCvDownloadDialogVisibility(true);
                  }}
                  content={t('employee-detail.button-cv-download-en')}
                />
                <Button
                  icon={<DownloadIcon />}
                  iconPosition="before"
                  primary
                  onClick={() => {
                    setCvLanauge('de');
                    setCvDownloadDialogVisibility(true);
                  }}
                  content={t('employee-detail.button-cv-download-de')}
                />
              </Flex>
            </FlexItem>
          </Flex>
        </Flex>
        {employeeTab === 'masterData' && (
          <EmployeeMasterDataTab
            employee={employee}
            isEditable={isEditable}
            showSensitiveData={showSensitiveData}
          />
        )}
        {employeeTab === 'skills' && (
          <EmployeeSkillsTab isEditable={isEditable} employee={employee} />
        )}
        {employeeTab === 'projects' && (
          <EmployeeProjectsTab
            isEditable={isEditable}
            employeeId={id}
            projects={employee?.projects}
            templateBasedProjects={employee?.templateBasedProjects}
            allowReferenceProjectToggle={!maxReferenceProjectsReached}
          />
        )}
      </Flex>
    </BasicLayout>
  );
};
