import { Flex } from '@fluentui/react-northstar';
import { EmployeeDto } from '../../data-access';
import { EmployeeAdditionalPersonalDataCard } from './EmployeeMasterCards/EmployeeAdditionalPersonalData/EmployeeAdditionalPersonalDataCard';
import { EmployeeCertificatesCard } from './EmployeeMasterCards/EmployeeCertificates/EmployeeCertificatesCard';
import { EmployeeEducationCard } from './EmployeeMasterCards/EmployeeEducation/EmployeeEducationCard';
import { EmployeeProfileTextCard } from './EmployeeMasterCards/EmployeeProfileText/EmployeeProfileTextCard';
import { EmployeePersonalDataCard } from './EmployeeMasterCards/EmployeePersonalData/EmployeePersonalDataCard';
import { EmployeeNationalSecurityCard } from './EmployeeMasterCards/EmployeeNationalSecurity/EmployeeNationalSecurityCard';
import { DefaultLoader } from '../../admin/components/DefaultLoader';

type EmployeeMasterDataProps = {
  employee?: EmployeeDto;
  isEditable: boolean;
  showSensitiveData: boolean;
};

export const EmployeeMasterDataTab = ({
  employee,
  isEditable,
  showSensitiveData,
}: EmployeeMasterDataProps) => {
  return (
    <Flex column gap="gap.large" style={{ marginTop: '24px' }}>
      {employee ? (
        <>
          <EmployeePersonalDataCard
            employee={employee}
            showSensitiveData={showSensitiveData}
            isEditable={isEditable}
          />
          <EmployeeAdditionalPersonalDataCard
            employee={employee}
            isEditable={isEditable}
            showSensitiveData={showSensitiveData}
          />
          <EmployeeProfileTextCard employee={employee} isEditable={isEditable} />
          <EmployeeEducationCard employee={employee} isEditable={isEditable} />
          <EmployeeCertificatesCard employee={employee} isEditable={isEditable} />
          <EmployeeNationalSecurityCard employee={employee} isEditable={isEditable} />
        </>
      ) : (
        <DefaultLoader isLoading={true} />
      )}
    </Flex>
  );
};
