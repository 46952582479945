import { Card, CardBody } from '@fluentui/react-northstar';
import { EmployeeDto } from '../../../../data-access';
import { useTranslation } from 'react-i18next';
import { EmployeePersonalData } from './EmployeePersonalData';
import { useState } from 'react';
import { Ti8mCardHeader } from '../../../common/Ti8mCardHeader';

type EmployeePersonalDataCardProps = {
  employee: EmployeeDto;
  isEditable?: boolean;
  showSensitiveData?: boolean;
};

export const EmployeePersonalDataCard = ({
  employee,
  isEditable,
  showSensitiveData,
}: EmployeePersonalDataCardProps) => {
  const { t } = useTranslation();
  const [isPersonalDataCardUnfold, setIsPersonalDataCardUnfold] = useState<boolean>(true);

  return (
    <Card>
      <Ti8mCardHeader
        cardHeaderText={t('employee-detail.master-data.personal-data.header')}
        isEditable={false}
        isCardUnfold={isPersonalDataCardUnfold}
        setCardUnfold={setIsPersonalDataCardUnfold}
      />
      {isPersonalDataCardUnfold && (
        <CardBody>
          <EmployeePersonalData
            employee={employee}
            isEditable={isEditable}
            showSensitiveData={showSensitiveData}
          />
        </CardBody>
      )}
    </Card>
  );
};
