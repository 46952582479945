import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, EmployeeService } from '../../open-api/queries';
import { EmployeeDtoResult } from '../../open-api/queries/models/EmployeeDtoResult';
import { QueryKeys } from '../../query-keys';
import { EmployeeDto } from '../../open-api/queries/models/EmployeeDto';

const getEmployee = (id?: string | null) =>
  EmployeeService.employeeById({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

export const useEmployee = (
  id?: string | null,
  queryOptions?: UseQueryOptions<
    EmployeeDtoResult,
    ApiError,
    EmployeeDto,
    [QueryKeys.EmployeeDetail, string | undefined | null]
  >
) =>
  useQuery([QueryKeys.EmployeeDetail, id], () => getEmployee(id), {
    enabled: !!id,
    select: (data) => data.payload,
    ...queryOptions,
  });
