import { Text, Flex, Grid, InfoIcon, Tooltip } from '@fluentui/react-northstar';
import { EmployeeDto } from '../../../../data-access';
import { useTranslation } from 'react-i18next';
import { convertToMonthDayYear } from '../../../../utils/dateConverter';
import { PersonalInformation } from './PersonalInformation';
import { useMemo } from 'react';

type EmployeePersonalDataProps = {
  employee?: EmployeeDto;
  isEditable?: boolean;
  showSensitiveData?: boolean;
};

export const EmployeePersonalData = ({
  employee,
  isEditable,
  showSensitiveData,
}: EmployeePersonalDataProps) => {
  const { t } = useTranslation();

  const showBirthYear = useMemo(() => {
    return showSensitiveData || isEditable;
  }, [showSensitiveData, isEditable]);

  return (
    <Flex column gap="gap.large">
      <Grid columns={showBirthYear ? 'repeat(5,1fr)' : 'repeat(4,1fr)'}>
        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.email-label')}
          data={employee?.eMail ?? '—'}
        />
        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.department')}
          data={employee?.organization?.name ?? '—'}
        />
        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.pensum')}
          data={employee?.pensum.toString().concat(' %') ?? '—'}
        />
        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.start-date')}
          data={employee?.startDate ? convertToMonthDayYear(employee.startDate) : '—'}
        />
        {showBirthYear && (
          <Flex gap="gap.small">
            <PersonalInformation
              label={t('employee-detail.master-data.personal-data.content.year-of-birth')}
              data={employee?.birthYear ? employee.birthYear : '—'}
            />
            <Tooltip
              trigger={<InfoIcon outline />}
              content={
                <Text
                  content={t(
                    'employee-detail.master-data.personal-data.content.year-of-birth-warning'
                  )}
                />
              }
            />
          </Flex>
        )}
      </Grid>
    </Flex>
  );
};
