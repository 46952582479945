import { Flex } from '@fluentui/react-northstar';
import { SearchWithFilter } from '../components/EmployeeSearch/SearchWithFilter';
import { EmployeeTable } from '../components/EmployeeTable/EmployeeTable';
import { BasicLayout } from '../components/Layout/Layout';

export const EmployeePage = () => {
  return (
    <BasicLayout>
      <Flex column gap="gap.large">
        <SearchWithFilter />
        <EmployeeTable />
      </Flex>
    </BasicLayout>
  );
};
