import { Checkbox, Flex, FlexItem, Input, Text, TextArea } from '@fluentui/react-northstar';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeDto, GenerateCvDto, TranslationDto } from '../../../data-access';
import { Ti8mDropdown } from '../../common';
import {
  GenerateCvDropdownItem,
  useGenerateCvDropdownItems,
} from './hooks/useGenerateCvDropdownItems';

export const maxEducationCount = 3;
export const maxProjectsCount = 2;
export const maxSectorCount = 5;
export const maxSkillCount = 5;
export const maxCertificateCount = 3;

export const mapLanguageToNumber = {
  en: 0,
  de: 1,
} as const;

export type CvDownloadDialogFormProps = {
  employee: EmployeeDto;
  language: keyof TranslationDto;
  value: GenerateCvDto;
  onChanged: (value: GenerateCvDto) => void;
  canEditProfile?: boolean;
};

const itemsWithDisabled = (
  items: GenerateCvDropdownItem[],
  disabled: boolean
): GenerateCvDropdownItem[] =>
  items.map((item) => ({
    ...item,
    disabled: disabled,
  }));

const itemsWithFiltered = (
  items: GenerateCvDropdownItem[],
  filter: string[]
): GenerateCvDropdownItem[] => items.filter((item) => filter.includes(item.key));

export const CvDownloadDialogForm: React.FC<CvDownloadDialogFormProps> = ({
  employee,
  language,
  value,
  onChanged,
  canEditProfile,
}) => {
  const { t } = useTranslation();

  const { educations, projects, sectors, skills, certificates } = useGenerateCvDropdownItems(
    employee,
    language
  );

  useEffect(() => {
    onChanged({
      role: employee.role,
      sectorsKnowHow: sectors.map((sector) => sector.key).slice(0, maxSectorCount),
      topProjects: projects.map((p) => p.key).slice(0, maxProjectsCount),
      topSkills: skills.map((skill) => skill.key).slice(0, maxSkillCount),
      relevantCertificates: certificates
        .map((certificate) => certificate.key)
        .slice(0, maxCertificateCount),
      relevantEducations: educations.map((education) => education.key).slice(0, maxEducationCount),
      description: employee.profileText?.[language] ?? '',
      type: 0,
      language: mapLanguageToNumber[language],
      includeBirthYear: false,
    });
  }, [
    employee.role,
    employee.profileText,
    language,
    onChanged,
    sectors,
    projects,
    skills,
    certificates,
    educations,
  ]);

  const updateCvData = useCallback(
    <K extends keyof GenerateCvDto>(key: K, data: GenerateCvDto[K]) =>
      onChanged({ ...value, [key]: data }),
    [onChanged, value]
  );

  return (
    <Flex column gap="gap.medium">
      <Flex column gap="gap.smaller">
        <Text content={t('cv-download-dialog.role-header')} weight="semibold" />
        <Input
          autoFocus
          fluid
          value={value.role}
          onChange={(_, data) => updateCvData('role', data?.value ?? '')}
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text content={t('cv-download-dialog.abstract-header')} weight="semibold" />
        <TextArea
          value={value.description}
          styles={{ minHeight: '7rem' }}
          maxLength={350}
          onChange={(_, data) => updateCvData('description', data?.value ?? '')}
        />
        <FlexItem align="end">
          <Text content={`${value.description.length} / 350`} size="smaller" weight="light" />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.eduction-header')} weight="semibold" />
        <Ti8mDropdown
          key={`education`}
          multiple
          search
          position="above"
          align="top"
          items={itemsWithDisabled(
            educations,
            value.relevantEducations.length >= maxEducationCount
          )}
          value={itemsWithFiltered(educations, value.relevantEducations)}
          disabled={educations.length === 0}
          onChange={(data) =>
            updateCvData(
              'relevantEducations',
              (data.value as GenerateCvDropdownItem[] | undefined)?.map((item) => item.key) ?? []
            )
          }
        />
        <FlexItem align="end">
          <Text
            content={`${value.relevantEducations.length} / ${maxEducationCount}`}
            size="smaller"
            weight="light"
          />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.projects-header')} weight="semibold" />
        <Ti8mDropdown
          key={`project`}
          multiple
          search
          position="above"
          align="top"
          items={itemsWithDisabled(projects, value.topProjects.length >= maxProjectsCount)}
          value={itemsWithFiltered(projects, value.topProjects)}
          disabled={projects.length === 0}
          onChange={(data) =>
            updateCvData(
              'topProjects',
              (data.value as GenerateCvDropdownItem[] | undefined)?.map((item) => item.key) ?? []
            )
          }
        />
        <FlexItem align="end">
          <Text
            content={`${value.topProjects.length} / ${maxProjectsCount}`}
            size="smaller"
            weight="light"
          />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.sector-header')} weight="semibold" />
        <Ti8mDropdown
          key={`sector`}
          multiple
          search
          position="above"
          align="top"
          items={itemsWithDisabled(sectors, value.sectorsKnowHow.length >= maxSectorCount)}
          value={itemsWithFiltered(sectors, value.sectorsKnowHow)}
          disabled={sectors.length === 0}
          onChange={(data) =>
            updateCvData(
              'sectorsKnowHow',
              (data.value as GenerateCvDropdownItem[] | undefined)?.map((item) => item.key) ?? []
            )
          }
        />
        <FlexItem align="end">
          <Text
            content={`${value.sectorsKnowHow.length} / ${maxSectorCount}`}
            size="smaller"
            weight="light"
          />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.skills-header')} weight="semibold" />
        <Ti8mDropdown
          key={`skill`}
          multiple
          search
          position="above"
          align="top"
          items={itemsWithDisabled(skills, value.topSkills.length >= maxSkillCount)}
          value={itemsWithFiltered(skills, value.topSkills)}
          disabled={skills.length === 0}
          onChange={(data) =>
            updateCvData(
              'topSkills',
              (data.value as GenerateCvDropdownItem[] | undefined)?.map((item) => item.key) ?? []
            )
          }
        />
        <FlexItem align="end">
          <Text
            content={`${value.topSkills.length} / ${maxSkillCount}`}
            size="smaller"
            weight="light"
          />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.certificate-header')} weight="semibold" />
        <Ti8mDropdown
          key={`certificate`}
          multiple
          search
          position="above"
          align="top"
          items={itemsWithDisabled(
            certificates,
            value.relevantCertificates.length >= maxCertificateCount
          )}
          value={itemsWithFiltered(certificates, value.relevantCertificates)}
          disabled={certificates.length === 0}
          onChange={(data) =>
            updateCvData(
              'relevantCertificates',
              (data.value as GenerateCvDropdownItem[] | undefined)?.map((item) => item.key) ?? []
            )
          }
        />
        <FlexItem align="end">
          <Text
            content={`${value.relevantCertificates.length} / ${maxCertificateCount}`}
            size="smaller"
            weight="light"
          />
        </FlexItem>
      </Flex>
      {canEditProfile && (
        <Flex column gap="gap.small">
          <Text content={t('cv-download-dialog.include-birth-year')} weight="semibold" />
          <Checkbox
            toggle
            checked={value.includeBirthYear}
            onChange={(_, data) => updateCvData('includeBirthYear', !!data?.checked)}
          />
        </Flex>
      )}
    </Flex>
  );
};
