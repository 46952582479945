import { useRecoilValue } from 'recoil';
import { authAtom } from '../state/atoms/authAtom';
import { useCallback, useMemo } from 'react';

export const useAuthInfo = () => {
  const authInfo = useRecoilValue(authAtom);

  const { userRoles, accountId, runsInTeams } = authInfo;

  const isMasterDataEditor = useMemo(() => userRoles.includes('MasterDataEditor'), [userRoles]);
  const isGlobalProfileEditor = useMemo(
    () => userRoles.includes('GlobalProfileEditor'),
    [userRoles]
  );

  const isOwnProfile = useCallback(
    (employeeId?: string) => (employeeId ? employeeId === accountId : false),
    [accountId]
  );

  const canEditProfile = useCallback(
    (employeeId?: string) => isGlobalProfileEditor || isOwnProfile(employeeId),
    [isGlobalProfileEditor, isOwnProfile]
  );

  const hasRole = useCallback((role: string) => userRoles.includes(role), [userRoles]);

  return {
    runsInTeams,
    isOwnProfile,
    loginAccountId: accountId,
    canEditProfile,
    isMasterDataEditor,
    isGlobalProfileEditor,
    userRoles,
    hasRole,
  };
};
